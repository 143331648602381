import { ValidationType } from 'acquaint-api/validation/ValidationType';
import { FieldValidation } from 'acquaint-api/validation/FieldValidation';
import * as BLValidation from "bright-logic-core/validation/validation";
import { ValidationCallbackParams } from '../../types/validation/ValidationCallbackParams';
import { CountryCode } from 'bright-logic-core/src/CountryCode';

export function validationCallback(params: ValidationCallbackParams) {
    return validateField(params.value, params.rule.fieldValidation);
}

export function validateField(value: string | number, fieldValidation: FieldValidation) {
    switch (fieldValidation.validationType) {
        case ValidationType.Email:
            return typeof (value) === "number" ? false : BLValidation.email(value);
        case ValidationType.Currency:
            return BLValidation.currency(value);
        case ValidationType.CurrencyNeg:
            return BLValidation.currencyNeg(value);
        case ValidationType.DateTime:
            return typeof (value) === "number" ? false : BLValidation.date(value);
        case ValidationType.NameUpperLowerCase:
            return typeof (value) === "number" ? false : BLValidation.nameUpperLowerCase(value);
        case ValidationType.Numeric:
            return BLValidation.numeric(value);
        case ValidationType.NumericNeg:
            return BLValidation.numericNeg(value);
        case ValidationType.Percentage:
            return BLValidation.percentage(value);
        case ValidationType.WebPage:
            return typeof (value) === "number" ? false : BLValidation.webPage(value);
        case ValidationType.AccountSortCode:
            return BLValidation.accountSortCode(value);
        case ValidationType.AccountNumber:
            return BLValidation.accountNumber(value);
        case ValidationType.IBAN:
            return typeof (value) === "number" ? false : BLValidation.IBAN(value);
        case ValidationType.EPC:
            return BLValidation.EPC(value);
        case ValidationType.PropertyUniqueReferenceNumber:
            return BLValidation.propertyUniqueReferenceNumber(value);
        case ValidationType.BriefYourMarketUserName:
            return typeof (value) === "number" ? false : BLValidation.briefYourMarketUserName(value);
        case ValidationType.InternationalPassportNumber:
            return typeof (value) === "number" ? false : BLValidation.internationalPassportNumber(value);
        case ValidationType.UserPassword:
            return typeof (value) === "number" ? false : BLValidation.userPassword(value);
        case ValidationType.Username:
            return typeof (value) === "number" ? false : BLValidation.username(value);
        case ValidationType.MPRN:
            return typeof (value) === "number" ? false : BLValidation.mprn(value)
        case ValidationType.GPRN:
            return typeof (value) === "number" ? false : BLValidation.gprn(value);
        default:
            return true;
    }
}

export function validatePostcode (value: string, countryCode :CountryCode) {
    let length: number = 0;
    let outward: string = "";
    let inward: string = "";
    const regex = new RegExp("([A-Za-z])([0-9])([0-9wW])([A-Za-z0-9])([A-Za-z0-9])([A-Za-z0-9])([A-Za-z0-9])")

    value = value.trim();

    if (value === "") {
        return true;
    }
    switch (countryCode) {
        case CountryCode.UK:
        case CountryCode.Jersey:
        case CountryCode.Guernsey:
        case CountryCode.Gibraltar:
        case CountryCode.IsleOfMan:

            value = value.toUpperCase();

            if (value.includes("BFPO")) {
                return true;
            }

            if (value !== "") {
                value = value.replaceAll(' ', '');

                length = value.length;

                if ((length >= 5) && (length <= 7)) {
                    if ((BLValidation.isNumeric(value.charAt(value.length - 3)) === true) && (BLValidation.isNumeric(value.charAt(value.length - 2)) === false) && (BLValidation.isNumeric(value.charAt(value.length - 1)) === false)) {
                        outward = value.substring(0, value.length - 3);
                        inward = value.substring(value.length - 2);
                    }
                }

                if ((inward == "") || (outward == "")) {
                    return false;
                }
            }
            break;

        case CountryCode.France:
        case CountryCode.Germany:
        case CountryCode.Greece:
        case CountryCode.Italy:
        case CountryCode.Spain:
        case CountryCode.Turkey:
            if (value !== "") {
                value = value.replaceAll(' ', '')

                if ((value.length !== 5) || (BLValidation.isNumeric(value) == false)) {
                    return false;
                }
            }
            break;

        case CountryCode.Portugal:
            if (value !== "") {
                value = value.replaceAll(' ', '')

                if ((value.length !== 8) || (BLValidation.isNumeric(value.substring(0, 4)) == false) || (value.substring(4, 5) !== '-') || (BLValidation.isNumeric(value.substring(5, 8)) == false)) {
                    return false;
                }
            }
            break;

        case CountryCode.Ireland:
            if (value !== "") {
                value = value.replaceAll(" ", "");
                value = value.toUpperCase();

                if (value.length !== 7) {
                    return false;
                }

                if (regex.test(value) == false) {
                    return false;
                }
            }
            break;

    }

    return true;
}